import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/home',
		component: () => import('@/views/home/index'),

	},
  {
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/index'),
    meta: { isAuth: true, },

	},
  {
		path: '/policy',
		name: 'login',
		component: () => import('@/views/policy/index'),
    meta: { isAuth: true, },

	},
	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/home/index'),

	},
  {
		path: '/facialVerification',
		name: 'facialVerification',
		component: () => import('@/views/facialVerification/index'),

	},
  {
		path: '/faceApi',
		name: 'faceApi',
		component: () => import('@/views/facialVerification/faceApi'),

	},
  {
		path: '/panCard',
		name: 'panCard',
		component: () => import('@/views/panCard/index'),

	},
  {
		path: '/contacts',
		name: 'contacts',
		component: () => import('@/views/contacts/index'),

	},
	{
		path: '/extend',
		name: 'extend',
		component: () => import('@/views/extend/index'),

	},

	{
		path: '/bankcard',
		name: 'bankcard',
		component: () => import('@/views/bankcard/index'),


	},

	{
		path: '/deletePersonaldata',
		name: 'deletePersonaldata',
		component: () => import('@/views/deletePersonaldata/index'),

	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/about/index'),

	},
	{
		path: '/delete',
		name: 'delete',
		component: () => import('@/views/delete/index'),

	},


  // ----------------
  {
		path: '/identification',
		name: 'identification',
		component: () => import('@/views/identification/index'),

	},
  {
		path: '/ifsc',
		name: 'ifsc',
		component: () => import('@/views/ifsc/index'),

	},
  {
		path: '/hasVisa',
		name: 'hasVisa',
		component: () => import('@/views/hasVisa/index'),
	},
  {
		path: '/customerService',
		name: 'customerService',
		component: () => import('@/views/customerService/index'),
	},
  {
		path: '/profile',
		name: 'profile',
		component: () => import('@/views/profile/index'),
	},
  {
		path: '/personalInformation',
		name: 'personalInformation',
		component: () => import('@/views/personalInformation/index'),
	},
  {
		path: '/loan',
		name: 'loan',
		component: () => import('@/views/loan/index'),
	},
  {
		path: '/loanRecords',
		name: 'loanRecords',
		component: () => import('@/views/loanRecords/index'),

	},
  {
		path: '/repayment',
		name: 'repayment',
		component: () => import('@/views/repayment/index'),

	},
  {
		path: '/repaymentDetails',
		name: 'repaymentDetails',
		component: () => import('@/views/repaymentDetails/index'),

	},
  {
		path: '/utr',
		name: 'utr',
		component: () => import('@/views/utr/index'),

	},
  {
		path: '/test',
		name: 'test',
		component: () => import('@/views/test'),

	},


]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
	routes
})
router.beforeEach((to, from, next) => {
  document.title=`10100-${to.name}`
  if (to.meta.isAuth) {
    next();
  }else{
    if (JSON.parse(localStorage.getItem("userInfo"))) {
      next();
    } else if(!JSON.parse(localStorage.getItem("userInfo"))) {
      router.push('/login')
    }

  }


})

export default router
