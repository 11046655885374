import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/styles/index.scss'
import 'amfe-flexible' //


// import '@vant/touch-emulator';


import Vant from 'vant';
import 'vant/lib/index.css';
import '@/styles/vant.css'
import touch from 'vue-directive-touch';
Vue.use(touch);

import VueTouch from "vue-touch";
Vue.use(VueTouch, { name: "v-touch" });

//
import { getRandomAlphaNum } from '@/utils/config.js'
Vue.prototype.getRandomAlphaNum = getRandomAlphaNum
import { preventReClick } from '@/utils/directive.js'
Vue.directive('preventReClick', preventReClick)



//
// import Vconsole from 'vconsole';
// let vconsole = new Vconsole;
// vconsole

// Vue.prototype.$api = api
import '@/styles/index.scss'
import '@/icons' // icon
Vue.use(Vant);
Vue.config.productionTip = false

//
import * as filters from './filters' // global filters
// register global utility filters
Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})




new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
