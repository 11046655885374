<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getBrowserType } from "@/utils/getBrowserName";
export default {
  data() {
    return {
      position: {
        latitude: null,
        longitude: null,
      },
      options: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    };
  },

  async mounted() {


    const browserType = await getBrowserType();
    localStorage.setItem("browserType", browserType);
    let u = navigator.userAgent;
    console.log('name:',navigator.appName);
    console.log('u:',u);
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
    if (isAndroid) {
      localStorage.setItem("osType", "android");
    } else if (isIOS) {
      localStorage.setItem("osType", "ios");
    } else {
      localStorage.setItem("osType", "pc");
    }
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
